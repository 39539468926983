import { Form, Button, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom'
import axios from "axios";
import React, { useState } from "react";
import Cookies from 'js-cookie';

export function AddAll() {
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substr(0, 10);
  const fill_texts_dict = {
    "NAME": { "label": "Nazwisko i imie", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
    "PHONE_NUMBER": { "label": "Numer telefonu", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
    "ADDRESS": { "label": "Adres", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
    "PRICE": { "label": "Cena", "placeholder": "", "error_text": "", "data_type": "REAL", "value": 0 },
    "ADVANCE": { "label": "Zaliczka", "placeholder": "", "error_text": "", "data_type": "REAL", "value": 0 },
    "EXPENSE": { "label": "Koszt", "placeholder": "", "error_text": "", "data_type": "REAL", "value": 0 },
    "UNIT": { "label": "Nazwa jednostki", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
    "MONTAGE_DATE": { "label": "Data zamontowania", "placeholder": "", "error_text": "", "data_type": "DATE", "value": "" },
    "ADDED_DATE": { "label": "Data dodania", "placeholder": "", "error_text": "", "data_type": "DATE", "value": date },
  };
  const select_elements_dict = {
    "MONTAGE_DISTANCE": { "label": "Dystans", "placeholder": "", "error_text": "", "data_type": "TEXT", "elements": ["Przez ściane", "Do 5m", "Dłuższy", " "], "value": " " },
    "MOUNTED": { "label": "Zamontowane", "placeholder": "", "error_text": "", "data_type": "TEXT", "elements": [" ", "Tak", "Nie"], "value": "Nie" },
    "PRODUCT_ORDERED": { "label": "Towar zamów.", "placeholder": "", "error_text": "", "data_type": "TEXT", "elements": [" ", "Tak", "Nie"], "value": "Nie" },
    "MONTAGE_PLACE": { "label": "Montaż na", "placeholder": "", "error_text": "", "data_type": "TEXT", "elements": ["Elewacji", "Gruncie", " "], "value": " " },
  };

  let handleSubmit = event => {
    event.preventDefault();
    let json = {}
    for (var key in fill_texts_dict) {
      if (fill_texts_dict.hasOwnProperty(key)) {
        json[key] = fill_texts_dict[key]["value"]
      }
    }
    for (var key in select_elements_dict) {
      if (select_elements_dict.hasOwnProperty(key)) {
        json[key] = select_elements_dict[key]["value"]
      }
    }
    AddToDatabase()
  }

  function AddToDatabase() {
    let json = {}
    for (var key in fill_texts_dict) {
      if (fill_texts_dict.hasOwnProperty(key)) {
        json[key] = fill_texts_dict[key]["value"]
      }
    }
    for (var key in select_elements_dict) {
      if (select_elements_dict.hasOwnProperty(key)) {
        json[key] = select_elements_dict[key]["value"]
      }
    }
    axios.post(`https://hyperion2.srv22208.microhost.com.pl/add_air_conditioning.php`,
      json,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
      .then(res => {
        window.location.reload(false);
      })
  }
  function FillTextElement(element_key) {
    let control_by_type = <Form.Control type="text" placeholder={fill_texts_dict[element_key]["placeholder"]} defaultValue=' ' onChange={(e) => { fill_texts_dict[element_key]["value"] = e.target.value; }} />
    if (fill_texts_dict[element_key]["data_type"] === "REAL") {
      control_by_type = <Form.Control type='number' step="0.1" min='0' max='999999999' defaultValue='0' placeholder={fill_texts_dict[element_key]["placeholder"]} onChange={(e) => { fill_texts_dict[element_key]["value"] = e.target.value; }} />
    }
    else if (fill_texts_dict[element_key]["data_type"] === "DATE") {
      if (element_key === 'ADDED_DATE') {
        var curr = new Date();
        curr.setDate(curr.getDate());
        var date = curr.toISOString().substr(0, 10);
        control_by_type = <Form.Control type='date' defaultValue={date} placeholder={fill_texts_dict[element_key]["placeholder"]} onChange={(e) => { fill_texts_dict[element_key]["value"] = e.target.value; }} />
      }
      else
        control_by_type = <Form.Control type='date' placeholder={fill_texts_dict[element_key]["placeholder"]} onChange={(e) => { fill_texts_dict[element_key]["value"] = e.target.value; }} />

    }


    return (
      <>
        <Form.Group controlId={element_key}>
          <Form.Label>{fill_texts_dict[element_key]["label"]}</Form.Label>
          {control_by_type}
          <Form.Text className="text-muted">{fill_texts_dict[element_key]["error_text"]}</Form.Text>
        </Form.Group>
      </>
    );
  }

  function SelectElement(element_key) {
    return (
      <>
        <Form.Group controlId={element_key}>
          <Form.Label>{select_elements_dict[element_key]["label"]}</Form.Label>
          <Form.Control as="select"
            onChange={(e) => {
              select_elements_dict[element_key]["value"] = e.target.value;
            }}
          >
            {
              select_elements_dict[element_key]["elements"].map((element) => {
                return <option selected={true}>{element}</option>
              }
              )
            }
          </Form.Control>
        </Form.Group>
      </>
    );
  }

  let elements = []
  for (var key in fill_texts_dict) {
    if (fill_texts_dict.hasOwnProperty(key)) {
      elements.push(FillTextElement(key))
    }
  }

  for (var key in select_elements_dict) {
    if (select_elements_dict.hasOwnProperty(key)) {
      elements.push(SelectElement(key))
    }
  }
  if (Cookies.get('Rights') !== '' && Cookies.get('Rights') !== null && Cookies.get('Rights') !== 'readonly') {
    return (
      <>
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
          integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
          crossorigin="anonymous"
        />
        <div className="row justify-content-around">
          <div className="col-2 pt-5">
            <Button href="/#/instalacje/dodaj" variant="primary">Dodaj fotowoltaike</Button>
          </div>
          <div className="col-2 pt-5">
            <Button href="/#/klimatyzacje/dodaj" variant="primary">Dodaj klimatyzacje</Button>
          </div>
          <div className="col-2 pt-5">
            <Button href="/#/pompy/dodaj" variant="primary">Dodaj pompe</Button>
          </div>
          <div className="col-2 pt-5">
            <Button href="/#/zadania/dodaj" variant="primary">Dodaj zadanie Mariusz</Button>
          </div>
          <div className="col-2 pt-5">
            <Button href="/#/zadania_biurowe/dodaj" variant="primary">Dodaj zadanie biuro</Button>
          </div>
        </div>

      </>
    );
  }
  else {
    return <></>
  }
}

export default withRouter(AddAll);
