import React, { useState, useEffect } from "react";
import { Button, Modal, Tabs, Tab, Row, Col } from "react-bootstrap";
import SingleProduct from "./components/SingleProduct";
import axios from "axios";

export default function Wiew_warehouse() {
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [Data, setData] = useState({});
  const [Destinations, setDestinations] = useState([]);
  const [SoldItems, setSoldItems] = useState([]);
  const [OrderedItemsQuantities, setOrderedItemsQuantities] = useState([]);
  const [history, sethistory] = useState();
  const [test, settest] = useState(0);
  const moveUp = async (current, prew, tabName) => {
    let newData = Data[tabName].items;
    let all = Data;
    console.log(newData);
    let order_number = newData[current].order_number;
    let prewOrder_number = newData[prew].order_number;

    console.log(all);
    let json = {
      order_edited: true,
      order_number: order_number,
    };
    let json2 = {
      order_edited: true,
      order_number: prewOrder_number,
    };
    console.log(prewOrder_number, order_number);
    if (prewOrder_number !== order_number) {
      try {
        let response = await axios.put(
          `https://hyperion2.srv22208.microhost.com.pl/warehouse/warehouse_items.php?id=${newData[current].id}`,
          json2,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response);
        let response2 = await axios.put(
          `https://hyperion2.srv22208.microhost.com.pl/warehouse/warehouse_items.php?id=${newData[prew].id}`,
          json,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response2);
        if (response.status === 200 && response2.status === 200) {
          console.log("zmiana");
          newData[current].order_number = prewOrder_number;
          newData[prew].order_number = order_number;
          newData.sort((a, b) => {
            return parseInt(a.order_number) - parseInt(b.order_number);
          });
          all[tabName].items = newData;
          settest((current) => {
            return current + 1;
          });
          setData(all);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("takie samo order number");
    }
  };
  const moveDown = async (current, next, tabName) => {
    let newData = Data[tabName].items;
    let all = Data;
    console.log(newData);
    let order_number = newData[current].order_number;
    let nextOrder_number = newData[next].order_number;

    console.log(all);
    let json = {
      order_edited: true,
      order_number: order_number,
    };
    let json2 = {
      order_edited: true,
      order_number: nextOrder_number,
    };
    if (nextOrder_number !== order_number) {
      try {
        let response = await axios.put(
          `https://hyperion2.srv22208.microhost.com.pl/warehouse/warehouse_items.php?id=${newData[current].id}`,
          json2,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response);
        let response2 = await axios.put(
          `https://hyperion2.srv22208.microhost.com.pl/warehouse/warehouse_items.php?id=${newData[next].id}`,
          json,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response2);
        if (response.status === 200 && response2.status === 200) {
          console.log("zmiana");
          newData[current].order_number = nextOrder_number;
          newData[next].order_number = order_number;
          newData.sort((a, b) => {
            return parseInt(a.order_number) - parseInt(b.order_number);
          });
          all[tabName].items = newData;
          settest((current) => {
            return current + 1;
          });
          setData(all);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("takie samo order number");
    }
  };
  const getWarehause = async () => {
    setModalShow(false);
    try {
      let response = await axios.get(
        " https://hyperion2.srv22208.microhost.com.pl/warehouse/warehouse_items.php"
      );
      console.log(response.data);
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
    getHistory();
  };
  const getSoldItems = async () => {
    try {
      let response = await axios.get(
        "https://hyperion2.srv22208.microhost.com.pl/warehouse/sold_items.php"
      );
      console.log(response);
      setSoldItems(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getOrderedItemsQuantities = async () => {
    try {
      let response = await axios.get(
        "https://hyperion2.srv22208.microhost.com.pl/warehouse/ordered_items_quantities.php"
      );
      console.log(response);
      setOrderedItemsQuantities(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getDestination = async () => {
    try {
      let response = await axios.get(
        "https://hyperion2.srv22208.microhost.com.pl/warehouse/destinations.php"
      );
      console.log(response);
      setDestinations(response.data);
      getWarehause();
    } catch (error) {
      console.log(error);
    }
  };
  const getHistory = async () => {
    try {
      let response = await axios.get(
        "https://hyperion2.srv22208.microhost.com.pl/warehouse/get_edit_history.php"
      );
      console.log(response);
      sethistory(response.data);
    } catch (error) {
      console.log(error.response);
    }
  };
  useEffect(() => {
    getDestination();
    getSoldItems();
    getOrderedItemsQuantities();
    getHistory();
  }, []);

  return (
    <>
      <div className="my-3 px-3">
        <Button
          variant="primary"
          className="mx-2"
          onClick={() => setModalShow(true)}
        >
          Dodaj
        </Button>
        <Button
          variant="primary"
          className="mx-2"
          onClick={() => setModalShow2(true)}
        >
          Historia zmian
        </Button>
      </div>
      <Tabs
        defaultActiveKey="Fotowoltaika"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        {Object.keys(Data).map((e) => {
          return (
            <Tab eventKey={Data[e].name} title={Data[e].name}>
              {Data[e].items
                .sort((a, b) => {
                  return parseInt(a.order_number) - parseInt(b.order_number);
                })
                .map((element, index, array) => {
                  if (Data[e].items.length === index + 1) {
                    return (
                      <SingleProduct
                        movedown={moveDown}
                        moveup={moveUp}
                        tab={e}
                        soldItems={SoldItems}
                        array={array}
                        index={index}
                        orderedItemsQuantities={OrderedItemsQuantities}
                        key={element.id}
                        element={element}
                        destinations={Destinations}
                        refresh={getDestination}
                        last={true}
                      />
                    );
                  } else {
                    return (
                      <SingleProduct
                        movedown={moveDown}
                        moveup={moveUp}
                        tab={e}
                        soldItems={SoldItems}
                        array={array}
                        index={index}
                        orderedItemsQuantities={OrderedItemsQuantities}
                        key={element.id}
                        element={element}
                        destinations={Destinations}
                        refresh={getDestination}
                        last={false}
                      />
                    );
                  }
                })}
            </Tab>
          );
        })}
      </Tabs>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => getWarehause()}
        destinations={Destinations}
      />
      <MyVerticallyCenteredModal2
        show={modalShow2}
        onHide={() => setModalShow2(false)}
        history={history}
      />
    </>
  );
}
function MyVerticallyCenteredModal(props) {
  const [Destination, setDestination] = useState("");
  const [Name, setName] = useState("");
  const [Quantity, setQuantity] = useState("");
  const [PurchasePrice, setPurchasePrice] = useState("1");
  const [SellingPrice, setSellingPrice] = useState("1");
  useEffect(() => {
    if (props.destinations[0] !== undefined && Destination === "") {
      setDestination(props.destinations[0].destination);
    }
  }, [props]);
  const reset = () => {
    setDestination(props.destinations[0].destination);
    setName("");
    setQuantity("");
    setPurchasePrice("1");
    setSellingPrice("1");
  };
  const add = async () => {
    let json = {
      name: Name,
      destination: Destination,
      quantity: parseInt(Quantity),
      purchase_price: parseFloat(PurchasePrice.replace(/,/g, ".")),
      selling_price: parseFloat(SellingPrice.replace(/,/g, ".")),
    };
    try {
      let response = await axios.post(
        "https://hyperion2.srv22208.microhost.com.pl/warehouse/warehouse_items.php",
        json,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);
      props.onHide();
      reset();
    } catch (error) {
      console.log(error);
      reset();
    }
  };
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div class="form-group">
          <label for="exampleFormControlSelect1">Przypisz do</label>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            onChange={(e) => setDestination(e.target.value)}
          >
            {props.destinations.map((element, index) => {
              if (index === 0) {
                return (
                  <option
                    selected={true}
                    key={element.destination}
                    value={element.destination}
                  >
                    {element.name}
                  </option>
                );
              } else {
                return (
                  <option key={element.destination} value={element.destination}>
                    {element.name}
                  </option>
                );
              }
            })}
          </select>
        </div>
        <div class="form-group">
          <label for="exampleFormControlInput1">Nazwa</label>
          <input
            type="text"
            class="form-control"
            value={Name}
            onChange={(e) => setName(e.target.value)}
            id="exampleFormControlInput1"
          />
        </div>
        <div class="form-group">
          <label for="exampleFormControlInput1">Ilość</label>
          <input
            type="number"
            class="form-control"
            value={Quantity}
            onChange={(e) => setQuantity(e.target.value)}
            id="exampleFormControlInput1"
          />
        </div>
        {/*                <div class="form-group"> 
                    <label for="exampleFormControlInput1">Cena zakupu</label>
                    <CurrencyInput
                        id="input-example"
                        name="input-name"
                        class="form-control"
                        decimalsLimit={2}
                        decimalScale={2}
                        value={PurchasePrice}
                        onValueChange={(value) => setPurchasePrice(value)}
                    //onValueChange={(value) => console.log(parseFloat(value.replace(/,/g, '.')))}
                    />
                </div>
                <div class="form-group">
                    <label for="exampleFormControlInput1">Cena sprzedaży</label>
                    <CurrencyInput
                        id="input-example"
                        name="input-name"
                        class="form-control"
                        decimalsLimit={2}
                        decimalScale={2}
                        value={SellingPrice}
                        onValueChange={(value) => setSellingPrice(value)}
                    />
                </div> */}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} variant="danger">
          Zamknij
        </Button>
        <Button onClick={() => add()} variant="success">
          Dodaj
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal2(props) {
  const [History, setHistory] = useState({});

  useEffect(() => {
    if (props.history !== undefined) {
      setHistory(props.history);
    }
  }, [props]);
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Tabs
          defaultActiveKey="Fotowoltaika"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          {Object.keys(History).map((e) => {
            return (
              <Tab eventKey={History[e].name} title={History[e].name}>
                {History[e].items
                  .sort((a, b) => {
                    let data1 = new Date(a.change_date);
                    let data2 = new Date(b.change_date);
                    return (
                      parseInt(data2.getTime()) - parseInt(data1.getTime())
                    );
                  })
                  .map((element, index, array) => {
                    if (index <= 100) {
                      return (
                        <Row className="py-2 border-bottom">
                          <Col xs="3">{element.name}</Col>
                          <Col xs="5">{element.change_type}</Col>
                          <Col xs="1">
                            {element.change_quantity !== "0.0"
                              ? element.change_quantity
                              : null}
                          </Col>
                          <Col xs="3">
                            {(parseInt(
                              new Date(element.change_date).getDate()
                            ) <= 9
                              ? "0" + new Date(element.change_date).getDate()
                              : new Date(element.change_date).getDate()) +
                              "-" +
                              (new Date(element.change_date).getMonth() + 1 <= 9
                                ? "0" +
                                (new Date(element.change_date).getMonth() + 1)
                                : new Date(element.change_date).getMonth() +
                                1) +
                              "-" +
                              new Date(element.change_date).getFullYear()}
                          </Col>
                        </Row>
                      );
                    }
                  })}
              </Tab>
            );
          })}
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} variant="danger">
          Zamknij
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
