import React from 'react';
import ViewMarianTasks from './view_marian_tasks'
import AddTask from './add_task';
import AddServices from './add_services';
import Login from './login'
import ViewInstallationsAsKrystian from './view_installation_as_krystian'
import ViewInstallationsAsAdmin from './view_installation_as_admin'
import ViewInstallationsAsSales from './view_installation_as_sales'
import ViewInstallationsAsMontage from './view_installation_as_montage'
import ViewServices from './view_installation_services';
import ViewAirConditioningAsAdmin from './view_air_conditioning_as_admin'
import ViewAirConditioningAsMontage from './view_air_conditioning_as_montage'
import ViewWarehouse from './view_warehouse'
import ViewHeatAsAdmin from './view_heat_as_admin';
import AddPumpHeat from './add_heat_pump';
import { Link, withRouter } from 'react-router-dom'
import AddAll from './add_all';
import AddInstallation from './add_installation'
import AddAirConditioning from './add_air_conditioning'
import AddOfficeTask from './add_office_task';
import ViewOfficeTasks from './view_office_tasks';
import { Container, Nav, Navbar, Dropdown } from 'react-bootstrap';
import HoverControlledDropdown from './components/HoverControlledDropdown';
import Cookies from "js-cookie";
import './App.css'
import AddRecuperation from './add_recuperation'
import ViewRecuperation from './view_recuperation'
import ViewHeatAsMontage from "./view_heat_as_montage";
import AddElewacje from './add_elewacje';
import AddInneOcieplenia from './add_inne_ocieplenia';
import AddPiecDrewno from './add_piec_drewno';
import AddPiecGazowy from './add_piec_gazowy';
import ViewElewacje from './view_elewacje';
import ViewInneOcieplenia from './view_inne_ocieplenia';
import ViewPiecGazowy from './view_piec_gazowy';
import ViewPiecDrewno from './view_piec_drewno';

import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";



export function Main() {
  const MyRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
  return (
    <>
      <Router>

        <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark">
          <Container fluid>
            <Navbar.Brand>
              <img
                alt=""
                src="https://static.wixstatic.com/media/de8b96_1dd12fda107345d1997e5d5688675002~mv2.png/v1/fill/w_183,h_116,al_c,q_85,usm_0.66_1.00_0.01/logo%20bia%C5%82e.webp"
                width="50"
                height="30"
                className="d-inline-block align-top"
              />{' '}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                {(Cookies.get("Rights") === "admin" || Cookies.get("Rights") === "handel" || Cookies.get("Rights") === "superadmin") ?
                  <Nav.Item>
                    <HoverControlledDropdown className="px-1">
                      <Dropdown.Toggle id="dropdown-button-dark-example" variant="dark">
                        Dodaj
                      </Dropdown.Toggle>

                      <Dropdown.Menu variant="dark">
                        <Dropdown.Item href="/#/instalacje/dodaj">Fotowoltaika</Dropdown.Item>
                        <Dropdown.Item href="/#/klimatyzacje/dodaj">Klimatyzacje</Dropdown.Item>
                        <Dropdown.Item href="/#/pompy/dodaj">Pompy Ciepła</Dropdown.Item>
                        <Dropdown.Item href="/#/rekuperacje/dodaj">Rekuperacje</Dropdown.Item>
                        <Dropdown.Item href="/#/elewacje/dodaj">Ocieplenia</Dropdown.Item>
                        <Dropdown.Item href="/#/inne_ocieplenia/dodaj">Piec pellet</Dropdown.Item>
                        <Dropdown.Item href="/#/piec_gazowy/dodaj">Piec Gazowy</Dropdown.Item>
                        <Dropdown.Item href="/#/piec_drewno/dodaj">Piec Drewno</Dropdown.Item>
                        <Dropdown.Item href="/#/serwis/dodaj">Serwis</Dropdown.Item>
                        <Dropdown.Item href="/#/zadania/dodaj">Zadanie Mariusz</Dropdown.Item>
                        <Dropdown.Item href="/#/zadania_biurowe/dodaj">Zadanie biuro</Dropdown.Item>

                      </Dropdown.Menu>
                    </HoverControlledDropdown>
                  </Nav.Item> : <></>}

                {(Cookies.get("Rights") === "admin" || Cookies.get("Rights") === "handel" || Cookies.get("Rights") === "superadmin") || Cookies.get("Rights") === "readonly" ?
                  <Nav.Item>
                    <HoverControlledDropdown className="px-1">
                      <Dropdown.Toggle id="dropdown-button-dark-example" variant="dark">
                        Administracja
                      </Dropdown.Toggle>

                      <Dropdown.Menu variant="dark">
                        <Dropdown.Item href="/#/instalacje/administracja">Fotowoltaika</Dropdown.Item>
                        <Dropdown.Item href="/#/klimatyzacje/administracja">Klimatyzacje</Dropdown.Item>
                        <Dropdown.Item href="/#/rekuperacje/administracja">Rekuperacje</Dropdown.Item>
                        <Dropdown.Item href="/#/elewacje/administracja">Ocieplenia</Dropdown.Item>
                        <Dropdown.Item href="/#/inne_ocieplenia/administracja">Piec pellet</Dropdown.Item>
                        <Dropdown.Item href="/#/piec_gazowy/administracja">Piec Gazowy</Dropdown.Item>
                        <Dropdown.Item href="/#/piec_drewno/administracja">Piec Drewno</Dropdown.Item>
                        <Dropdown.Item href="/#/pompy/administracja">Pompy Ciepła</Dropdown.Item>
                        {
                          Cookies.get("Rights") === "superadmin" ? <Dropdown.Item href="/#/instalacje/krystian">Krystian instalacje</Dropdown.Item> : null
                        }
                      </Dropdown.Menu>
                    </HoverControlledDropdown>
                  </Nav.Item> : <></>}
                {
                  <Nav.Item>
                    <HoverControlledDropdown className="px-1">
                      <Dropdown.Toggle id="dropdown-button-dark-example" variant="dark">
                        Monterzy
                      </Dropdown.Toggle>

                      <Dropdown.Menu variant="dark">
                        <Dropdown.Item href="/#/instalacje/monterzy">Fotowoltaika</Dropdown.Item>
                        <Dropdown.Item href="/#/klimatyzacje/monterzy">Klimatyzacje</Dropdown.Item>
                        <Dropdown.Item href="/#/pompy/monterzy">Pompy Ciepła</Dropdown.Item>
                        <Dropdown.Item href="/#/rekuperacje/administracja">Rekuperacje</Dropdown.Item>
                        <Dropdown.Item href="/#/elewacje/administracja">Ocieplenia</Dropdown.Item>
                        <Dropdown.Item href="/#/inne_ocieplenia/administracja">Piec pellet</Dropdown.Item>
                        <Dropdown.Item href="/#/piec_gazowy/administracja">Piec Gazowy</Dropdown.Item>
                        <Dropdown.Item href="/#/piec_drewno/administracja">Piec Drewno</Dropdown.Item>
                      </Dropdown.Menu>
                    </HoverControlledDropdown>
                  </Nav.Item>
                }

                <Nav.Item>
                  <HoverControlledDropdown className="px-1">
                    <Dropdown.Toggle id="dropdown-button-dark-example" variant="dark">
                      Serwisy
                    </Dropdown.Toggle>

                    <Dropdown.Menu variant="dark">
                      <Dropdown.Item to="/serwisy/instalacje" as={Link}>
                        Fotowoltaika
                      </Dropdown.Item>
                      <Dropdown.Item to="/serwisy/klimatyzacje" as={Link}>
                        Klimatyzacje
                      </Dropdown.Item>
                      <Dropdown.Item to="/serwisy/pompy" as={Link}>
                        Pompy Ciepła
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </HoverControlledDropdown>
                </Nav.Item>

                {/* <Nav.Item><Nav.Link className='border-right' href="/#/instalacje/administracja">Administracja fotowoltaika</Nav.Link></Nav.Item> */}
                {Cookies.get("Rights") === "serwis" ? <></> : <Nav.Item><Nav.Link className="nav-text" href="/#/instalacje/handlowcy">Handlowcy fotowoltaika</Nav.Link></Nav.Item>}

                {/* <Nav.Item><Nav.Link className='border-right' href="/#/instalacje/monterzy">Monterzy fotowoltaika</Nav.Link></Nav.Item> */}
                {/* <Nav.Item><Nav.Link className='border-right' href="/#/klimatyzacje/administracja">{ (Cookies.get("Rights") === "superadmin" || Cookies.get("Rights") === "regular") ? "Administracja klimatyzacje" : ""}</Nav.Link></Nav.Item> */}
                {/* <Nav.Item><Nav.Link className='border-right' href="/#/klimatyzacje/monterzy">Monterzy klimatyzacje</Nav.Link></Nav.Item> */}
                {Cookies.get("Rights") === "serwis" ? <></> : <Nav.Item><Nav.Link className="nav-text" href="/#/zadania/przegladaj">Zadania</Nav.Link></Nav.Item>}
                {Cookies.get("Rights") === "serwis" ? <></> : <Nav.Item><Nav.Link className="nav-text" href="/#/zadania_biurowe/przegladaj">Zadania biuro</Nav.Link></Nav.Item>}
                <Nav.Item><Nav.Link className="nav-text" href="/hyperion_energy.db">{(Cookies.get("Rights") === "admin" || Cookies.get("Rights") === "superadmin") ? "Kopia bazy" : ""}</Nav.Link></Nav.Item>
                {/* <Nav.Item><Nav.Link className='border-right' href="/#/pompy/administracja">Pompy ciepła</Nav.Link></Nav.Item> */}

                {
                  Cookies.get("Rights") === "superadmin" || Cookies.get("Rights") === "montaz" || Cookies.get("Rights") === "montaz" ? <Nav.Item><Nav.Link className="nav-text" href="/#/magazyn">Magazyn</Nav.Link></Nav.Item> : null
                }
                <Nav.Item><Nav.Link className="nav-text" href="/#/zaloguj">{(Cookies.get("Nick") == null) ? 'Zaloguj' : Cookies.get("Nick") + ' - (zaloguj)'}</Nav.Link></Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Switch>
          <MyRoute path="/dodaj" component={AddAll} />
          <MyRoute path="/instalacje/dodaj" component={AddInstallation} />
          <MyRoute path="/instalacje/administracja" component={ViewInstallationsAsAdmin} />
          <MyRoute path="/instalacje/krystian" component={ViewInstallationsAsKrystian} />
          <MyRoute path="/instalacje/handlowcy" component={ViewInstallationsAsSales} />
          <MyRoute path="/instalacje/monterzy" component={ViewInstallationsAsMontage} />
          <MyRoute path="/klimatyzacje/dodaj" component={AddAirConditioning} />
          <MyRoute path="/klimatyzacje/administracja" component={ViewAirConditioningAsAdmin} />
          <MyRoute path="/rekuperacje/dodaj" component={AddRecuperation} />
          <MyRoute path="/rekuperacje/administracja" component={ViewRecuperation} />
          <MyRoute path="/klimatyzacje/monterzy" component={ViewAirConditioningAsMontage} />
          <MyRoute path="/pompy/administracja" component={ViewHeatAsAdmin} />
          <MyRoute path="/pompy/monterzy" component={ViewHeatAsMontage} />
          <MyRoute path="/serwisy/:type" component={ViewServices} />
          <MyRoute path="/serwis/dodaj" component={AddServices} />
          <MyRoute path="/pompy/dodaj" component={AddPumpHeat} />
          <MyRoute path="/zadania/dodaj" component={AddTask} />
          <MyRoute path="/zadania/przegladaj" component={ViewMarianTasks} />
          <MyRoute path="/zadania_biurowe/dodaj" component={AddOfficeTask} />
          <MyRoute path="/zadania_biurowe/przegladaj" component={ViewOfficeTasks} />
          <MyRoute path="/elewacje/dodaj" component={AddElewacje} />
          <MyRoute path="/inne_ocieplenia/dodaj" component={AddInneOcieplenia} />
          <MyRoute path="/piec_gazowy/dodaj" component={AddPiecGazowy} />
          <MyRoute path="/piec_drewno/dodaj" component={AddPiecDrewno} />
          <MyRoute path="/elewacje/administracja" component={ViewElewacje} />
          <MyRoute path="/inne_ocieplenia/administracja" component={ViewInneOcieplenia} />
          <MyRoute path="/piec_gazowy/administracja" component={ViewPiecGazowy} />
          <MyRoute path="/piec_drewno/administracja" component={ViewPiecDrewno} />
          <MyRoute path="/zaloguj" component={Login} />
          <MyRoute path="/magazyn" component={ViewWarehouse} />
          <MyRoute path="/" component={Login} />
        </Switch>
      </Router>
    </>
  );
}

export default withRouter(Main);
