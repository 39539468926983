import { Form, Button, Row, Col, Badge } from 'react-bootstrap';
import { withRouter } from 'react-router-dom'
import axios from "axios";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
export function ViewMarianTasks() {

    const [data, setData] = useState([{}]);
    const [test, settest] = useState(0);
    const [i, setindex] = useState(null);
    const [dirction, setDirction] = useState('');
    const [timer, settimer] = useState(false);
    const moveUp = async (current, prew) => {

        let newData = data
        let all = data
        console.log(newData)
        let order_number = newData[current].order_number
        let prewOrder_number = newData[prew].order_number
        newData[current].order_number = prewOrder_number
        newData[prew].order_number = order_number
        newData.sort((a, b) => {
            return parseInt(a.order_number) - parseInt(b.order_number)
        })
        all = newData


        console.log(all)
        try {
            let response = await axios.post(`https://hyperion2.srv22208.microhost.com.pl/edit_task.php?id=${newData[current].ID}`,
                all[current],
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            console.log(response)
            let response2 = await axios.post(`https://hyperion2.srv22208.microhost.com.pl/edit_task.php?id=${newData[prew].ID}`,
                all[prew],
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            console.log(response2)
            if (response2.status === 200 && response.status === 200) {
                await getData();
                setDirction('up')
                setindex(current)
                clearTimeout(timer);
                settimer(setTimeout(function () {
                    setindex(null)
                    setDirction('')
                }, 1000));
            }
        } catch (error) {
            console.log(error)
        }
        settest(current => {
            return current + 1
        })
    }
    const moveDown = async (current, next) => {

        let newData = data
        let all = data
        console.log(newData)
        let order_number = newData[current].order_number
        let nextOrder_number = newData[next].order_number
        newData[current].order_number = nextOrder_number
        newData[next].order_number = order_number
        newData.sort((a, b) => {
            return parseInt(a.order_number) - parseInt(b.order_number)
        })
        all = newData
        console.log(all)
        try {
            let response = await axios.put(`https://hyperion2.srv22208.microhost.com.pl/edit_task.php?id=${newData[current].ID}`,
                all[current],
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            console.log(response)
            let response2 = await axios.put(`https://hyperion2.srv22208.microhost.com.pl/edit_task.php?id=${newData[next].ID}`,
                all[next],
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            console.log(response2)
            if (response2.status === 200 && response.status === 200) {
                await getData();
                setDirction('down')
                setindex(current)
                clearTimeout(timer);
                settimer(setTimeout(function () {
                    setindex(null)
                    setDirction('')
                }, 1000));
            }
        } catch (error) {
            console.log(error)
        }
        settest(current => {
            return current + 1
        })
    }
    const getData = async () => {
        try {
            if (Cookies.get("Rights") === "admin" || Cookies.get("Rights") === "handel" || Cookies.get("Rights") === "superadmin" || Cookies.get("Rights") === "montaz" || Cookies.get("Rights") === "montaz" || Cookies.get("Rights") === 'readonly') {
                const response = await axios.get(`https://hyperion2.srv22208.microhost.com.pl/get_tasks.php`);
                setData([{}]);
                console.log(response.data);
                setData(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getData();
    }, [])

    const fill_texts_dict = {
        "NAME": { "label": "Nazwisko i imie", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
        "PHONE_NUMBER": { "label": "Numer telefonu", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
        "ADDRESS": { "label": "Adres", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
        "WHAT_TO_DO": { "label": "Co do zrobienia", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
    };
    const select_elements_dict = {
        "FINISHED": { "label": "Zakończone", "placeholder": "", "error_text": "", "data_type": "TEXT", "elements": [" ", "Tak", "Nie"], "value": "" }
    };

    function handleSaveEdited(installation_index) {
        let installation = data[installation_index]
        let installation_id = data[installation_index].ID
        if (installation.hasOwnProperty('ID'))
            delete installation['ID']
        axios.post(`https://hyperion2.srv22208.microhost.com.pl/edit_task.php?id=` + installation_id,
            installation,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then(res => {
            getData()
        })
    }

    function handleDelete(installation_index) {
        let installation_id = data[installation_index].ID
        axios.get(`https://hyperion2.srv22208.microhost.com.pl/delete_task.php?id=` + installation_id,).then(res => {
            getData()
        })
    }


    function FillTextElement(element_key, installation_index) {
        let control_by_type = <Form.Control className="m-0" as="textarea" rows={1} type="text" defaultValue={data[installation_index][element_key]} placeholder={fill_texts_dict[element_key]["placeholder"]} onChange={(e) => { data[installation_index][element_key] = e.target.value; }} />
        if (fill_texts_dict[element_key]["data_type"] === "REAL") {
            control_by_type = <Form.Control className="m-0" type='number' step="0.1" min='0' max='999999999' defaultValue={data[installation_index][element_key]} placeholder={fill_texts_dict[element_key]["placeholder"]} onChange={(e) => { data[installation_index][element_key] = e.target.value; }} />
        }
        if (fill_texts_dict[element_key]["data_type"] === "DATE") {
            control_by_type = <Form.Control className="m-0" type='date' defaultValue={data[installation_index][element_key]} placeholder={fill_texts_dict[element_key]["placeholder"]} onChange={(e) => { data[installation_index][element_key] = e.target.value; }} />
        }

        if (fill_texts_dict[element_key]["label"] === "Co do zrobienia") {
            return (
                <>
                    <Col className="m-0 col-6 col-md-6 col-lg-4">
                        <Form.Group className="m-0" controlId={element_key}>
                            <Form.Label className="m-0" size="sm">{fill_texts_dict[element_key]["label"]}</Form.Label>
                            {control_by_type}
                        </Form.Group>
                    </Col>
                </>
            );
        }
        return (
            <>
                <Col className="m-0 col-6 col-md-3 col-lg-2">
                    <Form.Group className="m-0" controlId={element_key}>
                        <Form.Label className="m-0" size="sm">{fill_texts_dict[element_key]["label"]}</Form.Label>
                        {control_by_type}
                    </Form.Group>
                </Col>
            </>
        );
    }

    function SelectElement(element_key, installation_index) {
        return (
            <>
                <Col className="m-0 col-4 col-md-2 col-lg-1">
                    <Form.Group className="m-0" controlId={element_key}>
                        <Form.Label className="m-0">{select_elements_dict[element_key]["label"]}</Form.Label>
                        <Form.Control className="m-0" as="select"
                            onChange={(e) => {
                                let d = data;
                                d[installation_index][element_key] = e.target.value;
                                setData(d);
                            }}
                        >
                            {
                                select_elements_dict[element_key]["elements"].map((element) => {
                                    return <option selected={element === data[installation_index][element_key] ? true : false}>{element}</option>
                                }
                                )
                            }
                        </Form.Control>
                    </Form.Group>
                </Col>
            </>
        );
    }
    function columnedView(installation_index) {
        let elements = []
        for (var key in fill_texts_dict) {
            if (fill_texts_dict.hasOwnProperty(key)) {
                elements.push(FillTextElement(key, installation_index))
            }
        }

        for (var key in select_elements_dict) {
            if (select_elements_dict.hasOwnProperty(key)) {
                elements.push(SelectElement(key, installation_index))
            }
        }
        let elements0_9 = []
        let elements10_19 = []
        for (let step = 0; step < elements.length; step++) {
            if (step < 10)
                elements0_9.push(elements[step])
            else
                elements10_19.push(elements[step])
        }

        function color_by_status() {
            if (data[installation_index].FINISHED === "Tak")
                return { 'background-color': '#8ae0ff', border: '2px solid #000' }
            return { 'background-color': '#fff', border: '2px solid #000' }
        }

        return (
            <>
                <div style={color_by_status()} class={dirction === 'up' ? i !== null && i - 1 === installation_index ? "bg-info" : '' : dirction === 'down' ? i !== null && i + 1 === installation_index ? "bg-info" : '' : ''}>
                    <div style={{ margin: '-2px 2px 0px 2px' }}>
                        <Form className="m-0 pb-2">
                            <Row>
                                <Col className="m-0">
                                    <h3>
                                        <Badge className="mt-0" variant="info">{data[installation_index].ID}</Badge>
                                        <Button className='mx-1' variant="primary" size='sm' onClick={() => handleSaveEdited(installation_index)}>Zapisz</Button>
                                        <Button className='mr-3' variant="danger" size='sm' onClick={() => { if (window.confirm('Czy na pewno chcesz usunąć instalacje ' + (installation_index + 1) + ' ?')) handleDelete(installation_index) }}>Usuń</Button>
                                    </h3>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                {elements.map((elem) => (
                                    elem
                                ))}
                                <Col xs={1} className="d-flex align-items-center ">
                                    <Row className="mx-0">
                                        <Col xs={12} className="d-flex align-items-center ">

                                            <Button onClick={() => { moveUp(installation_index, installation_index - 1) }} variant="link" className='mx-3 upDown' disabled={installation_index === 0}>
                                                <FaAngleUp />
                                            </Button>

                                        </Col>
                                        <Col xs={12} className="d-flex align-items-center ">

                                            <Button onClick={() => { moveDown(installation_index, installation_index + 1) }} variant="link" className='mx-3 upDown' disabled={data.length - 1 === installation_index}>
                                                <FaAngleDown />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </>
        );
    }


    return (
        <>
            <link
                rel="stylesheet"
                href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
                integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
                crossorigin="anonymous"
            />

            <div>

                {data.sort((a, b) => {
                    return parseInt(a.order_number) - parseInt(b.order_number)
                }).map((elem, index) => {
                    return columnedView(index)
                })}

            </div>
        </>
    );
}

export default withRouter(ViewMarianTasks);
