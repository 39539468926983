/*NAME 
ADDRESS
PHONE_NUMBER
UNIT
-MONTAGE_DISTANCE
ADVANCE
PRICE
-MOUNTED
-PRODUCT_ORDERED
-MONTAGE_PLACE
-MONTAGE_DATE*/

import {
  Form,
  Button,
  Row,
  Col,
  Badge,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { withRouter } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import FileBase64 from "react-file-base64";

export function ViewInneOcieplenia() {
  const [data, setData] = useState([]);
  useEffect(() => {
    console.log("data", data);
  }, [data]);

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [allYears, setAllYears] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [warehouseModalData, setWarehouseModalData] = useState([]);
  const [itemIndex, setItemIndex] = useState(0);
  const [modalTitle, setModalTitle] = useState("");
  const [itemArrayIndex, setItemArrayIndex] = useState(0);
  const [totalAdvances, setTotalAdvances] = useState(0);
  const [installations_arr, setInstallations_arr] = useState([]);
  const [shure_clients, setShure_clients] = useState(0);
  const [totalOrdersValue, setTotalOrdersValue] = useState(0);
  let date = "0000-00";

  const getData = async () => {
    try {
      if (Cookies.get("Rights") === "superadmin") {
        const response = await axios.get(
          `https://hyperion2.srv22208.microhost.com.pl/get_financial_data.php?table=INNE_OCIEPLENIA&year=${selectedYear}`
        );
        setTotalAdvances(response.data.ADVANCES);
        setTotalOrdersValue(response.data.ORDERS_VALUE);
      }
      if (
        Cookies.get("Rights") === "admin" ||
        Cookies.get("Rights") === "superadmin" ||
        Cookies.get("Rights") === "montaz" ||
        Cookies.get("Rights") === "readonly"
      ) {
        const response = await axios.get(
          `https://hyperion2.srv22208.microhost.com.pl/get_inne_ocieplenia.php?year=${selectedYear}`
        );
        const years = await axios.get(
          `https://hyperion2.srv22208.microhost.com.pl/get_years.php`
        );
        setAllYears(years.data);
        setData([]);
        console.log(response.data);
        setData(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    let shure_clients = 0;
    let installations = [];

    for (let i = 0; i < data.length; i++) {
      shure_clients++;
      installations.push(columnedView(i));
    }
    setInstallations_arr(installations);
    setShure_clients(shure_clients);
  }, [data]);

  const toggleYear = async (currentYear) => {
    let tempYear = currentYear;
    setSelectedYear(currentYear);
    if (Cookies.get("Rights") === "superadmin") {
      const response = await axios.get(
        `https://hyperion2.srv22208.microhost.com.pl/get_financial_data.php?table=INNE_OCIEPLENIA&year=${tempYear}`
      );
      setTotalAdvances(response.data.ADVANCES);
      setTotalOrdersValue(response.data.ORDERS_VALUE);
    }
    if (
      Cookies.get("Rights") === "admin" ||
      Cookies.get("Rights") === "superadmin" ||
      Cookies.get("Rights") === "montaz" ||
      Cookies.get("Rights") === "readonly"
    ) {
      await axios
        .get(
          `https://hyperion2.srv22208.microhost.com.pl/get_inne_ocieplenia.php?year=${tempYear}`
        )
        .then((e) => {
          setData([]);
          setData(e.data);
        });
    }
  };

  const fill_texts_dict = {
    NAME: {
      label: "Nazwisko i imie",
      placeholder: "",
      error_text: "",
      data_type: "TEXT",
      value: "",
    },
    PHONE_NUMBER: {
      label: "Numer telefonu",
      placeholder: "",
      error_text: "",
      data_type: "TEXT",
      value: "",
    },
    ADDRESS: {
      label: "Adres",
      placeholder: "",
      error_text: "",
      data_type: "TEXT",
      value: "",
    },
    PRICE: {
      label: "Cena",
      placeholder: "",
      error_text: "",
      data_type: "REAL",
      value: 0,
    },
    ADVANCE: {
      label: "Zaliczka",
      placeholder: "",
      error_text: "",
      data_type: "REAL",
      value: 0,
    },
    ADDITIONAL_INFORMATIONS: {
      label: "Dodatkowe informacje",
      placeholder: "",
      error_text: "",
      data_type: "TEXT",
      value: "",
    },
    MONTAGE_DATE: {
      label: "Data zamontowania",
      placeholder: "",
      error_text: "",
      data_type: "DATE",
      value: "",
    },
    ADDED_DATE: {
      label: "Data dodania",
      placeholder: "",
      error_text: "",
      data_type: "DATE",
      value: "",
    },
  };
  const select_elements_dict = {
    MOUNTED: {
      label: "Zamontowane",
      placeholder: "",
      error_text: "",
      data_type: "TEXT",
      elements: [" ", "Tak", "Nie"],
      value: "",
    },
    PRODUCT_ORDERED: {
      label: "Towar zam.",
      placeholder: "",
      error_text: "",
      data_type: "TEXT",
      elements: [" ", "Tak", "Nie"],
      value: "",
    },
    FINISHED: {
      label: "Zakończone",
      placeholder: "",
      error_text: "",
      data_type: "TEXT",
      elements: [" ", "Tak", "Nie"],
      value: "",
    },
  };

  const handleSaveEdited = async (installation_index) => {
    let installation = data[installation_index];
    console.log(data[installation_index]);
    let installation_id = data[installation_index].ID;


    if (installation.hasOwnProperty("ID")) delete installation["ID"];
    console.log(installation);
    await axios
      .post(
        `https://hyperion2.srv22208.microhost.com.pl/edit_inne_ocieplenia.php?id=` +
        installation_id,
        installation,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        getData();
      });
  };

  function handleDelete(installation_index) {
    let installation_id = data[installation_index].ID;
    axios
      .get(
        `https://hyperion2.srv22208.microhost.com.pl/delete_inne_ocieplenia.php?id=` +
        installation_id
      )
      .then((res) => {
        getData();
      });
  }

  function handleWarehouseAdd(firstJson, secondJson) {
    var tempData = [...data];
    const finalArray = firstJson.concat(secondJson);
    if (tempData[itemArrayIndex].MOUNTED == "Tak") {
      tempData[itemArrayIndex].SOLD_ITEMS = finalArray;
    }
    if (
      tempData[itemArrayIndex].MOUNTED == "Nie" ||
      tempData[itemArrayIndex].MOUNTED == ""
    ) {
      tempData[itemArrayIndex].WAREHOUSE_ITEMS = finalArray;
    }
    setData([]);
    setData(tempData);
  }

  function attachments(installation_index) {
    const File1 = (files) => {
      axios
        .post(
          `https://hyperion2.srv22208.microhost.com.pl/save_inne_ocieplenia_attachment.php`,
          {
            file: files.base64,
            filename: files.name
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .replace(/ /g, "_"),
            installation: data[installation_index].ID,
            attachment_nr: 1,
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          getData();
        });
    };
    const File2 = (files) => {
      axios
        .post(
          `https://hyperion2.srv22208.microhost.com.pl/save_inne_ocieplenia_attachment.php`,
          {
            file: files.base64,
            filename: files.name
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .replace(/ /g, "_"),
            installation: data[installation_index].ID,
            attachment_nr: 2,
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          getData();
        });
    };
    const File3 = (files) => {
      axios
        .post(
          `https://hyperion2.srv22208.microhost.com.pl/save_inne_ocieplenia_attachment.php`,
          {
            file: files.base64,
            filename: files.name
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .replace(/ /g, "_"),
            installation: data[installation_index].ID,
            attachment_nr: 3,
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          getData();
        });
    };

    function handleDownloadAttachment(installation_index, which_attachment) {
      if (which_attachment === 1)
        if (data[installation_index].ATTACHMENTS !== undefined) {
          if (data[installation_index].ATTACHMENTS !== "") {
            window.open(
              "https://hyperion2.srv22208.microhost.com.pl/" +
              data[installation_index].ATTACHMENTS,
              "_blank"
            );
            return 0;
          }
        }
      if (which_attachment === 2)
        if (data[installation_index].ATTACHMENT2 !== undefined) {
          if (data[installation_index].ATTACHMENT2 !== "") {
            window.open(
              "https://hyperion2.srv22208.microhost.com.pl/" +
              data[installation_index].ATTACHMENT2,
              "_blank"
            );
            return 0;
          }
        }
      if (which_attachment === 3)
        if (data[installation_index].ATTACHMENT3 !== undefined) {
          if (data[installation_index].ATTACHMENT3 !== "") {
            window.open(
              "https://hyperion2.srv22208.microhost.com.pl/" +
              data[installation_index].ATTACHMENT3,
              "_blank"
            );
            return 0;
          }
        }
    }

    function handleDeleteAttachment(installation_index, which_attachment) {
      if (which_attachment === 1)
        axios
          .post(
            `https://hyperion2.srv22208.microhost.com.pl/delete_inne_ocieplenia_attachment.php`,
            {
              filename: data[installation_index].ATTACHMENTS,
              installation: data[installation_index].ID,
              attachment_nr: which_attachment,
            },
            {
              "Content-Type": "application/json",
            }
          )
          .then((response) => {
            getData();
          });
      if (which_attachment === 2)
        axios
          .post(
            `https://hyperion2.srv22208.microhost.com.pl/delete_inne_ocieplenia_attachment.php`,
            {
              filename: data[installation_index].ATTACHMENT2,
              installation: data[installation_index].ID,
              attachment_nr: which_attachment,
            },
            {
              "Content-Type": "application/json",
            }
          )
          .then((response) => {
            getData();
          });
      if (which_attachment === 3)
        axios
          .post(
            `https://hyperion2.srv22208.microhost.com.pl/delete_inne_ocieplenia_attachment.php`,
            {
              filename: data[installation_index].ATTACHMENT3,
              installation: data[installation_index].ID,
              attachment_nr: which_attachment,
            },
            {
              "Content-Type": "application/json",
            }
          )
          .then((response) => {
            getData();
          });
    }

    let att1 = "Załącznik 1";
    let att1bool = false;
    let att2 = "Załącznik 2";
    let att2bool = false;
    let att3 = "Załącznik 3";
    let att3bool = false;
    if (data[installation_index].ATTACHMENTS !== undefined) {
      if (
        data[installation_index].ATTACHMENTS !== "" &&
        data[installation_index].ATTACHMENTS !== null
      ) {
        att1 = data[installation_index].ATTACHMENTS.substring(29, 47);
        att1bool = true;
      }
    }
    if (data[installation_index].ATTACHMENT2 !== undefined) {
      if (
        data[installation_index].ATTACHMENT2 !== "" &&
        data[installation_index].ATTACHMENT2 !== null
      ) {
        att2 = data[installation_index].ATTACHMENT2.substring(29, 47);
        att2bool = true;
      }
    }
    if (data[installation_index].ATTACHMENT3 !== undefined) {
      if (
        data[installation_index].ATTACHMENT3 !== "" &&
        data[installation_index].ATTACHMENT3 !== null
      ) {
        att3 = data[installation_index].ATTACHMENT3.substring(29, 47);
        att3bool = true;
      }
    }
    return (
      <>
        <Col className="m-0 col-12 col-md-4 col-lg-2">
          {att1bool ? (
            <div>
              <Button
                className="ml-2"
                variant="secondary"
                size="sm"
                onClick={() => handleDownloadAttachment(installation_index, 1)}
              >
                {att1}
              </Button>
              {Cookies.get("Rights") !== "readonly" ? (
                <Button
                  className="ml-1 mr-3"
                  variant="dark"
                  size="sm"
                  onClick={() => handleDeleteAttachment(installation_index, 1)}
                >
                  X
                </Button>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <FileBase64 multiple={false} onDone={File1.bind()} />
          )}
        </Col>
        <Col className="m-0 col-12 col-md-4 col-lg-2">
          {att2bool ? (
            <div>
              <Button
                className="mx-0"
                variant="secondary"
                size="sm"
                onClick={() => handleDownloadAttachment(installation_index, 2)}
              >
                {att2}
              </Button>
              {Cookies.get("Rights") !== "readonly" ? (
                <Button
                  className="ml-1 mr-3"
                  variant="dark"
                  size="sm"
                  onClick={() => handleDeleteAttachment(installation_index, 2)}
                >
                  X
                </Button>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <FileBase64 multiple={false} onDone={File2.bind()} />
          )}
        </Col>
        <Col className="m-0 col-12 col-md-4 col-lg-2">
          {att3bool ? (
            <div>
              <Button
                className="mx-0"
                variant="secondary"
                size="sm"
                onClick={() => handleDownloadAttachment(installation_index, 3)}
              >
                {att3}
              </Button>
              {Cookies.get("Rights") !== "readonly" ? (
                <Button
                  className="ml-1 mr-3"
                  variant="dark"
                  size="sm"
                  onClick={() => handleDeleteAttachment(installation_index, 3)}
                >
                  X
                </Button>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <FileBase64 multiple={false} onDone={File3.bind()} />
          )}
        </Col>
      </>
    );
  }

  function FillTextElement(element_key, installation_index) {
    let control_by_type = (
      <Form.Control
        className="m-0"
        as="textarea"
        rows={1}
        type="text"
        defaultValue={data[installation_index][element_key]}
        placeholder={fill_texts_dict[element_key]["placeholder"]}
        onChange={(e) => {
          data[installation_index][element_key] = e.target.value;
        }}
      />
    );
    if (fill_texts_dict[element_key]["data_type"] === "REAL") {
      if (
        (element_key === "PRICE" || element_key === "ADVANCE") &&
        Cookies.get("Rights") === "admin"
      ) {
        control_by_type = (
          <Form.Control
            className="m-0"
            disabled
            type="number"
            step="0.1"
            min="0"
            max="999999999"
            defaultValue={data[installation_index][element_key]}
            placeholder={fill_texts_dict[element_key]["placeholder"]}
            onChange={(e) => {
              data[installation_index][element_key] = e.target.value;
            }}
          />
        );
      } else if (
        (element_key === "PRICE" || element_key === "ADVANCE") &&
        Cookies.get("Nick") === "administracja"
      ) {
        control_by_type = <></>;
      } else {
        control_by_type = (
          <Form.Control
            className="m-0"
            type="number"
            step="0.1"
            min="0"
            max="999999999"
            defaultValue={data[installation_index][element_key]}
            placeholder={fill_texts_dict[element_key]["placeholder"]}
            onChange={(e) => {
              data[installation_index][element_key] = e.target.value;
            }}
          />
        );
      }
    }
    if (fill_texts_dict[element_key]["data_type"] === "DATE") {
      control_by_type = (
        <Form.Control
          className="m-0"
          type="date"
          defaultValue={data[installation_index][element_key]}
          placeholder={fill_texts_dict[element_key]["placeholder"]}
          onChange={(e) => {
            data[installation_index][element_key] = e.target.value;
          }}
        />
      );
    } else
      return (
        <>
          <Col className="m-0 col-6 col-md-3 col-lg-2">
            <Form.Group className="m-0" controlId={element_key}>
              <Form.Label className="m-0" size="sm">
                {fill_texts_dict[element_key]["label"]}
              </Form.Label>
              {control_by_type}
            </Form.Group>
          </Col>
        </>
      );
  }

  function SelectElement(element_key, installation_index) {
    return (
      <>
        <Col className="m-0 col-4 col-md-2 col-lg-1">
          <Form.Group className="m-0" controlId={element_key}>
            <Form.Label className="m-0">
              {select_elements_dict[element_key]["label"]}
            </Form.Label>
            <Form.Control
              className="m-0"
              as="select"
              onChange={(e) => {
                let d = data;
                d[installation_index][element_key] = e.target.value;
                setData(d);
              }}
            >
              {select_elements_dict[element_key]["elements"].map((element) => {
                return (
                  <option
                    selected={
                      element === data[installation_index][element_key]
                        ? true
                        : false
                    }
                  >
                    {element}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </Col>
      </>
    );
  }
  let id_in_month = 0;
  function columnedView(installation_index) {
    let elements = [];
    for (var key in fill_texts_dict) {
      if (fill_texts_dict.hasOwnProperty(key)) {
        elements.push(FillTextElement(key, installation_index));
      }
    }

    for (var key in select_elements_dict) {
      if (select_elements_dict.hasOwnProperty(key)) {
        elements.push(SelectElement(key, installation_index));
      }
    }
    let elements0_9 = [];
    let elements10_19 = [];
    for (let step = 0; step < elements.length; step++) {
      if (step < 10) elements0_9.push(elements[step]);
      else elements10_19.push(elements[step]);
    }

    function color_by_status() {
      if (data[installation_index].FINISHED === "Tak")
        return { "background-color": "#8ae0ff", border: "2px solid #000" };
      else if (
        parseFloat(data[installation_index].ADVANCE) >=
        parseFloat(data[installation_index].PRICE) &&
        data[installation_index].MOUNTED === "Tak"
      )
        return { "background-color": "#8aff8a", border: "2px solid #000" };
      else if (
        parseFloat(data[installation_index].ADVANCE) >=
        parseFloat(data[installation_index].PRICE) &&
        data[installation_index].MOUNTED !== "Tak"
      )
        return { "background-color": "#ffca8a", border: "2px solid #000" };
      else if (
        parseFloat(data[installation_index].ADVANCE) <=
        parseFloat(data[installation_index].PRICE) &&
        data[installation_index].MOUNTED === "Tak"
      )
        return { "background-color": "#ffca8a", border: "2px solid #000" };
      return { "background-color": "#fff", border: "2px solid #000" };
    }

    function idInMonth() {
      return ++id_in_month;
    }

    const handleOpenModal = async (dataIndex, dataArrayIndex) => {
      setModalTitle(
        dataIndex.ID + " - " + dataIndex.ADDRESS + " -" + dataIndex.NAME
      );
      setItemIndex(parseInt(dataIndex.ID));
      setItemArrayIndex(parseInt(dataArrayIndex));

      setOpenModal(true);
    };

    function date_plate() {
      function calculate_number_of_installations(sign_month, index) {
        let number_of_installation_in_month = 0;
        let current_sign_month = sign_month;
        for (let i = index; i < data.length; i++) {
          if (current_sign_month === sign_month) {
            if (i + 1 < data.length)
              if (
                current_sign_month === "jutro" ||
                current_sign_month === "dziś"
              )
                current_sign_month = "nieokreślono";
              else current_sign_month = data[i + 1].ADDED_DATE.substring(0, 7);
            else current_sign_month = "0000-00";

            number_of_installation_in_month++;
          } else break;
        }
        return number_of_installation_in_month;
      }
      if (data[installation_index].ADDED_DATE !== undefined) {
        let sign_month = data[installation_index].ADDED_DATE.substring(0, 7);
        if (date !== sign_month) {
          if (sign_month === "jutro" || sign_month === "dziś")
            sign_month = "nieokreślono";
          date = sign_month;
          id_in_month = 0;
          let num = calculate_number_of_installations(
            sign_month,
            installation_index
          );
          return (
            <h2>
              <Badge variant="success">{sign_month}</Badge>{" "}
              <Badge variant="success">Ilość w miesiącu {num}</Badge>
            </h2>
          );
        }
      }
      return <div></div>;
    }
    return (
      <>
        {date_plate()}
        <div style={color_by_status()}>
          <div style={{ margin: "-2px 2px 0px 2px", padding: "5px" }}>
            <Form className="m-0">
              <Row>
                <Col className="m-0">
                  <h3>
                    <Badge className="mt-0" variant="info">
                      {idInMonth()}
                    </Badge>{" "}
                    <Badge className="mt-0" variant="info">
                      {data[installation_index].ADDRESS} --{" "}
                      {data[installation_index].NAME}
                    </Badge>
                    {Cookies.get("Rights") !== "readonly" ? (
                      <Button
                        className="mx-1"
                        variant="primary"
                        size="sm"
                        onClick={() => handleSaveEdited(installation_index)}
                      >
                        Zapisz
                      </Button>
                    ) : (
                      <></>
                    )}
                    {Cookies.get("Rights") !== "montaz" &&
                      Cookies.get("Rights") !== "readonly" ? (
                      <Button
                        className="mr-3"
                        variant="danger"
                        size="sm"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Czy na pewno chcesz usunąć instalacje " +
                              (installation_index + 1) +
                              " ?"
                            )
                          )
                            handleDelete(installation_index);
                        }}
                      >
                        Usuń
                      </Button>
                    ) : (
                      <></>
                    )}

                  </h3>
                </Col>
                {attachments(installation_index)}
              </Row>

              <Row className="m-0">{elements.map((elem) => elem)}</Row>
            </Form>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
        integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
        crossorigin="anonymous"
      />

      <div className="d-flex mx-2 flex-row flex-wrap">
        <h2>
          <Badge variant="success">
            Ilość pewnych klientów {shure_clients}
          </Badge>
        </h2>
        <DropdownButton
          className="ml-5 mt-1"
          id="dropdown-basic-button"
          title={selectedYear}
        >
          {allYears &&
            allYears.map((element) => {
              return (
                <Dropdown.Item
                  style={{ minWidth: "unset" }}
                  key={`yearId_${element}`}
                  onClick={() => {
                    toggleYear(element);
                  }}
                >
                  {element}
                </Dropdown.Item>
              );
            })}
        </DropdownButton>
        {Cookies.get("Rights") === "superadmin" ? (
          <div className="d-flex flex-row">
            <h2 className="mx-2">
              <Badge variant="success">
                Wartość zamówień: {Math.round(totalOrdersValue)} zł
              </Badge>{" "}
            </h2>
            <h2 className="mx-2">
              <Badge variant="success">
                Zaliczki: {Math.round(totalAdvances)} zł
              </Badge>{" "}
            </h2>
            <h2 className="mx-2">
              <Badge variant="danger">
                Brakuje: {Math.round(totalOrdersValue - totalAdvances)} zł
              </Badge>{" "}
            </h2>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div>
        {installations_arr.map((elem) => elem)}
      </div>
    </>
  );
}

export default withRouter(ViewInneOcieplenia);
